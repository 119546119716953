<template>
  <div class="footer">
    <div class="footer-contact">
      <div class="content">
        <div class="footer-logo">
          <img src="../assets/img/logo-footer.png" alt="" />
        </div>
        <div class="contact-us">
          <h4>联系我们</h4>
          <p>客服电话：{{ service.service_phone }}</p>
          <p>客服邮箱：{{ service.email }}</p>
        </div>
        <div class="honours">
          <h4>企业荣誉</h4>
          <div class="honour-img">
            <a :href="item.link" target="_blank" v-for="(item, index) in honor" :key="index">
              <img :src="net + item.pic" alt="" />
            </a>
          </div>
        </div>
        <div class="download">
          <h4>下载</h4>
          <div class="download-qr">
            <div class="qr-item">
              <img :src="net + service.app_download" alt="" />
              <h5>APP下载</h5>
            </div>
            <div class="qr-item">
              <img :src="net + service.wechat" alt="" />
              <h5>官方微信</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="link">
        <span>友情链接:</span>
        <div class="links">
          <a :href="item.link" target="_blank" v-for="(item, index) in links" :key="index">{{
            item.title
          }}</a>
        </div>
      </div>
      <div class="copyright">
        ©copyright 2021-永远 汇成国际 hcgj.co 版权所有
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
          >辽ICP备2021009245号-2</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { getHonor, getHome, getLinks } from "@network/home";
export default {
  name: "Footer",
  data() {
    return {
      setting: {
        title: "",
        keywords: "",
        description: "",
      },
      net: BASE_URL,
      honor: [],
      service: {},
      links: [],
    };
  },

  created() {
    this.getHonor();
    this.getHome();
    this.getLinks();
  },

  metaInfo() {
    return {
      title: this.setting.title,
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },

  components: {},

  methods: {
    getHome() {
      getHome().then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error("获取失败");
          return false;
        } else {
          this.service = res.data.homePage;
          this.setting.title = res.data.homePage.website_name;
          this.setting.keywords = res.data.homePage.website_keywords;
          this.setting.description = res.data.homePage.website_desc;
        }
      });
    },
    //友情链接
    getLinks() {
      getLinks().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        }
        this.links = res.data;
      });
    },
    //企业荣誉
    getHonor() {
      getHonor().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        } else {
          this.honor = res.data;
        }
      });
    },
  },

  computed: {},
};
</script>
<style lang='less' scoped>
.footer {
  .footer-contact {
    background: #1a69d4;
    padding: 18px 0 31px;
    .content {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 25px;
      border-bottom: 1px solid #98a5ef;
    }
    .link {
      display: flex;
      width: 930px;
      margin: 31px auto 0;
      span {
        white-space: nowrap;
        font-size: 14px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #bbcaff;
        line-height: 20px;
      }
      a {
        display: inline-block;
        margin: 0 10px 20px 0;
        font-size: 14px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #bbcaff;
        line-height: 20px;
      }
    }
    .copyright {
      margin-top: 15px;
      text-align: center;
      font-size: 12px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #bbcaff;
      line-height: 17px;
      a {
        font-size: 12px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #bbcaff;
        line-height: 17px;
      }
    }
    .footer-logo {
      display: flex;
      align-items: center;
      img {
        width: 140px;
        height: 62px;
      }
    }
    .contact-us {
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #ffffff;
      h4 {
        text-align: center;
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 28px;
      }
      p {
        margin-top: 10px;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .honours {
      width: 220px;
      h4 {
        margin-bottom: 20px;
        text-align: center;
        font-size: 20px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 28px;
      }
      .honour-img {
        display: flex;
        flex-wrap: wrap;
        img {
          width: 100px;
        }
        a:nth-child(2n + 1) {
          margin-right: 20px;
        }
        a:nth-child(-n + 2) {
          margin-bottom: 20px;
        }
      }
    }
    .download {
      h4 {
        text-align: center;
        margin-bottom: 15px;
        font-size: 20px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 28px;
      }
      .download-qr {
        display: flex;
        .qr-item {
          margin: 0 15px;
          text-align: center;
          h5 {
            margin-top: 6px;
            font-size: 16px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 22px;
          }
          img {
            background: #fff;
            padding: 1px;
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }
}
</style>